
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { fixPrice } from '@/utils/general'
  import { mapActions } from 'vuex'
  import { Auto } from '@/entities/public'
  import { Query } from '@/entities/public/Resource/interfaces'
  import { formFilter } from '@/graphql/generated-types'
  import dayjs from 'dayjs'

@Component({
  components: {
    Row: () => import('../simple.vue'),
  },
  methods: {
    ...mapActions('resources/form', [
      'fetchData',
    ]),
    fixPrice,
  },
})

  export default class AutoTransferCost extends Vue {
  @Prop({ type: Object, default: () => ({}) }) value!: Auto
  cost: number = 0

  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  get idVersionYear () {
    if (this.value instanceof Auto) {
      return this.value?.version?.id
    }
  }

  get idGeneration () {
    if (this.value instanceof Auto) {
      return this.value?.generation?.id
    }
  }

  @Watch('value', { immediate: true, deep: true })
  async getCost () {
    if (!this.idGeneration) return
    const { idGeneration } = this

    const registration = await this.fetchData({
      query: { name: 'find', model: 'AutomotiveRegistration' },
      filter: {
        id_generation: { _eq: idGeneration },
      },
    })

    this.cost = registration?.find(reg => reg.year === Number(dayjs().format('YYYY')))?.permission
  }
  }
